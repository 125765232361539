import React, { useEffect, useState } from "react";
import { links } from "../content/Links";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-scroll";

const Navbar = () => {
  const [active, setActive] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [section, setSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      setSection(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="nav-bar fit-container box-pad-v-m fx-scattered">
        <div className="logo" />
        <div className="fx-scattered bg-light-black links">
          {links.map((link) => (
            <Link
              key={link._id}
              onClick={() => setActive(link._id)}
              to={link.name}
              smooth={true}
              duration={500}
              offset={-240}
              activeClass="active-link"
              spy
              className={`link ${
                section < 120 && link._id === 0 && "active-link"
              }`}
            >
              <h5 className="white-c">{link.entitle}</h5>
            </Link>
          ))}
        </div>

        <RouterLink to={"https://app.romy.eu"} target="_blank">
          <button className="btn btn-cnct">Se connecter</button>
        </RouterLink>
      </div>

      <>
        <div className="nav-bar-mobile fit-container box-pad-v-m fx-scattered">
          <div className="logo" />
          <div
            className={`add pointer`}
            style={{
              transform: isOpen && "rotate(45deg)",
              transition: "0.3s all",
            }}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>

        <div className={`mobile-links-container ${isOpen && "move-right"}`}>
          <div
            className="fit-container gap-32 fx-col"
            style={{ alignItems: "center" }}
          >
            <div className="fx-col gap-32">
              {links.map((link) => (
                <Link
                  key={link._id}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  to={link.name}
                  smooth={true}
                  duration={500}
                  offset={-240}
                  activeClass="blue-c"
                  spy
                  className={`link white-c pointer`}
                >
                  <h5 className={`p-centered`}>{link.entitle}</h5>
                </Link>
              ))}
            </div>

            <RouterLink to={"https://app.romyapp.io"} target="_blank">
              <button className="btn btn-cnct">Se connecter</button>
            </RouterLink>
          </div>
        </div>
      </>
    </>
  );
};

export default Navbar;
